export default {
  data() {
    return {
      translatedLabels: {
        labels: {
          buttonSkip: this.$t('tour.labels.skip'),
          buttonPrevious: this.$t('tour.labels.previous'),
          buttonNext: this.$t('tour.labels.next'),
          buttonStop: this.$t('tour.labels.finish')
        }
      },
      finishCallbackOnly: {
        onFinish: this.finishTour,
        onSkip: this.finishTour
      }
    }
  },
  mounted() {
    if (!this.$store.state.tour.hasSeen[this.$route.name] && !this.$store.state.tour.hasSkipped) {
      this.showNotification()
    }
  },
  methods: {
    askSkipTour() {
      this.$swal({
        title: this.$t('tour-manager_mixin.skip-tour-dialog.title'),
        text: this.$t('tour-manager_mixin.skip-tour-dialog.text'),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('tour-manager_mixin.skip-tour-dialog.confirm')
      }).then(result => {
        if (result.value) {
          // this.finishTour()
          this.$store.dispatch('tour/skip')
        } else {
          this.showNotification()
        }
      })
    },
    showNotification() {
      setTimeout(() => {
        this.$notify({
          group: 'with-action',
          type: 'warn',
          title: this.$t('notifications.first-time-using.title'),
          text: this.$t('notifications.first-time-using.text'),
          ignoreDuplicates: true,
          duration: -1,
          data: {
            actions: [
              {
                'action-text': this.$t('notifications.first-time-using.actions.start-tour'),
                'action-fn': () => {
                  this.startTour()
                }
              },
              {
                'action-text': this.$t('notifications.first-time-using.actions.skip-tour'),
                'action-fn': () => {
                  this.askSkipTour()
                }
              }
            ]
          }
        })
      }, 500)
    },
    startTour() {
      this.$tours[this.$route.name].start()
    },
    finishTour() {
      if (this.$route.name.includes('profile')) {
        this.$store.dispatch('tour/finished', 'profile-general')
        this.$store.dispatch('tour/finished', 'profile-security')
        this.$store.dispatch('tour/finished', 'profile-communitites')
      }
      if (this.$route.name.includes('admin')) {
        this.$store.dispatch('tour/finished', 'admin-general')
        this.$store.dispatch('tour/finished', 'admin-users')
        this.$store.dispatch('tour/finished', 'admin-access-codes')
        this.$store.dispatch('tour/finished', 'admin-status-codes')
        this.$store.dispatch('tour/finished', 'admin-driver-license')
      }

      this.$store.dispatch('tour/finished', this.$route.name)
    }
  }
}
