<template>
  <div class="characters v-step-all-characters">
    <ul v-if="!$store.state.selection.addingCharacter">
      <li
        v-for="character in $store.state.selection.characters"
        :key="character.id"
        @dblclick="$emit('doubleClickSelect')"
      >
        <input
          v-model="selectedCharacter"
          name="character-selection"
          :id="'box-' + character.id"
          type="radio"
          :value="character"
        />
        <label :for="'box-' + character.id">
          <div class="_box" :class="{ active: selectedCharacter && selectedCharacter.id == character.id }">
            <div class="edit">
              <a class="a-edit" @click="editCharacter(character)">
                <i class="fas fa-edit"></i>
              </a>
            </div>
            <div class="image">
              <i class="fas fa-male" v-if="character.gender === 'MALE'"></i>
              <i class="fas fa-female" v-if="character.gender === 'FEMALE'"></i>
              <i class="fas fa-question" v-if="character.gender === 'UNDEFINED'"></i>
            </div>
            <div class="name">
              <h3 class="primary">{{ character.firstName + ' ' + character.lastName }}</h3>
            </div>
            <div class="birthday">
              <h4 class="primary">
                ({{ moment(character.birthDate).format('YYYY-MM-DD') }} -
                {{ moment().diff(moment(character.birthDate), 'years') + ' ' + $t('selection.characters.years-old') }})
              </h4>
            </div>
          </div>
        </label>
      </li>
      <li
        class="add"
        :class="{ disabled: $store.getters['subscriptionPlanEnforcement/hasReachedMaxCivilians'] }"
        @click="
          !$store.getters['subscriptionPlanEnforcement/hasReachedMaxCivilians']
            ? $store.dispatch('selection/addingCharacter', true)
            : ''
        "
        :uk-tooltip="
          $store.getters['subscriptionPlanEnforcement/hasReachedMaxCivilians']
            ? `title: ${$t('selection.characters.has-reached-max-civilians')}; cls: uk-active`
            : `title: ; cls: col-display-none`
        "
      >
        <div class="_box v-step-add-character">
          <div class="icon">
            <span uk-icon="icon: plus; ratio: 5;"></span>
          </div>
          <div class="text">
            <p>{{ $t('selection.characters.add-a-character') }}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="add-a-character" v-if="$store.state.selection.addingCharacter">
      <div class="form col-lgpc-9 col-mdpc-10 col-mobile-11">
        <div class="first-name col-mobile-12">
          <p class="title">{{ $t('selection.characters.first-name') }}</p>
          <CustomInput
            class="custom-input line primary col-all-12"
            inputClasses="col-all-12"
            type="text"
            v-model.trim="$v.newCharacter.firstName.$model"
            :model="$v.newCharacter.firstName"
            :error="fieldsErrors.firstName"
          ></CustomInput>
        </div>
        <div class="last-name col-mobile-12">
          <p class="title">{{ $t('selection.characters.last-name') }}</p>
          <CustomInput
            class="custom-input line primary col-all-12"
            inputClasses="col-all-12"
            type="text"
            v-model.trim="$v.newCharacter.lastName.$model"
            :model="$v.newCharacter.lastName"
            :error="fieldsErrors.lastName"
          ></CustomInput>
        </div>
        <div class="dob">
          <p class="title">{{ $t('selection.characters.date-of-birth') }}</p>
          <VueDateTimePicker
            :onlyDate="true"
            format="YYYY-MM-DD"
            formatted="LL"
            v-model="$v.newCharacter.birthDate.$model"
            :error="!!fieldsErrors.birthDate"
            :showFromNow="true"
            :fromNowFormatter="
              value => '(' + moment().diff(moment(value), 'years') + ' ' + $t('civilian.years-old') + ')'
            "
          ></VueDateTimePicker>
        </div>
        <div class="gender">
          <p class="title">{{ $t('selection.characters.gender') }}</p>
          <vSelect
            v-model="$v.newCharacter.gender.$model"
            :reduce="gender => gender.value"
            class="custom-select primary line col-all-12"
            :class="{ orange: fieldsErrors.gender }"
            :clearable="false"
            :options="[
              { label: $t('gender-attribute.MALE'), value: 'MALE' },
              { label: $t('gender-attribute.FEMALE'), value: 'FEMALE' }
            ]"
          ></vSelect>
        </div>
        <div class="weight">
          <p class="title">{{ $t('selection.characters.weight') }}</p>
          <CustomInput
            class="custom-input primary line col-all-12"
            inputClasses="col-all-12"
            type="number"
            v-model="$v.newCharacter.weight.$model"
            :model="$v.newCharacter.weight"
            :error="fieldsErrors.weight"
            step="0.01"
          ></CustomInput>
        </div>
        <div class="height">
          <p class="title">{{ $t('selection.characters.height') }}</p>
          <CustomInput
            class="custom-input primary line col-all-12"
            inputClasses="col-all-12"
            type="number"
            v-model="$v.newCharacter.height.$model"
            :model="$v.newCharacter.height"
            :error="fieldsErrors.height"
            :onlyBooleanError="true"
            step="0.01"
          ></CustomInput>
        </div>
        <div class="race">
          <p class="title">{{ $t('selection.characters.ethnicity') }}</p>
          <vSelect
            v-model="$v.newCharacter.ethnicity.$model"
            :reduce="ethnicity => ethnicity.value"
            class="custom-select primary line col-pc-12"
            :class="{ orange: fieldsErrors.ethnicity }"
            :clearable="false"
            :options="[
              { label: $t('ethnic-group-attribute.HISPANIC'), value: 'HISPANIC' },
              { label: $t('ethnic-group-attribute.NATIVE'), value: 'NATIVE' },
              { label: $t('ethnic-group-attribute.ASIAN'), value: 'ASIAN' },
              { label: $t('ethnic-group-attribute.BLACK'), value: 'BLACK' },
              { label: $t('ethnic-group-attribute.WHITE'), value: 'WHITE' },
              { label: $t('ethnic-group-attribute.UNKNOWN'), value: 'UNKNOWN' }
            ]"
          ></vSelect>
        </div>
        <div class="eye-color">
          <p class="title">{{ $t('selection.characters.eye-color') }}</p>
          <vSelect
            v-model="$v.newCharacter.eyeColor.$model"
            :reduce="eyeColor => eyeColor.value"
            class="custom-select primary line col-pc-12"
            :class="{ orange: fieldsErrors.ethnicity }"
            :clearable="false"
            :options="[
              { label: $t('eye-color-attribute.BLUE'), value: 'BLUE' },
              { label: $t('eye-color-attribute.BROWN'), value: 'BROWN' },
              { label: $t('eye-color-attribute.GREEN'), value: 'GREEN' },
              { label: $t('eye-color-attribute.AMBER'), value: 'AMBER' },
              { label: $t('eye-color-attribute.OTHER'), value: 'OTHER' }
            ]"
          ></vSelect>
        </div>
        <div class="hair-color">
          <p class="title">{{ $t('selection.characters.hair-color') }}</p>
          <vSelect
            append-to-body
            :calculate-position="withPopper"
            v-model="$v.newCharacter.hairColor.$model"
            :reduce="hairColor => hairColor.value"
            class="custom-select primary line col-all-12 dropdown:line"
            :class="{ orange: fieldsErrors.hairColor }"
            :clearable="false"
            :options="[
              { label: $t('hair-color-attribute.AUBURN'), value: 'AUBURN' },
              { label: $t('hair-color-attribute.BLACK'), value: 'BLACK' },
              { label: $t('hair-color-attribute.BLOND'), value: 'BLOND' },
              { label: $t('hair-color-attribute.BROWN'), value: 'BROWN' },
              { label: $t('hair-color-attribute.REDHAIR'), value: 'REDHAIR' },
              { label: $t('hair-color-attribute.WHITE'), value: 'WHITE' },
              { label: $t('hair-color-attribute.BALD'), value: 'BALD' },
              { label: $t('hair-color-attribute.OTHER'), value: 'OTHER' },
              { label: $t('hair-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
            ]"
          ></vSelect>
        </div>
        <div class="randomize">
          <button class="primary-full col-all-12" :disabled="isRandomizeLoading" @click="randomizeCharacter">
            {{ $t('actions.randomize') }}
            <i class="fas fa-sync" :class="{ 'fa-spin': isRandomizeLoading }"></i>
          </button>
        </div>
      </div>
    </div>

    <v-tour
      :name="this.$route.name"
      :steps="tourSteps"
      :callbacks="finishCallbackOnly"
      :options="translatedLabels"
    ></v-tour>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/selection/selection-boxes.less"></style>

<script>
import DropdownPositionCalculator from '@/mixins/DropdownPositionCalculator'
import TourManager from '@/mixins/TourManager'
import { mapGetters } from 'vuex'
import validationMessage from '@/validationMapping'
import { required, decimal, helpers } from 'vuelidate/lib/validators'

const alphaDash = helpers.regex('alpha', /^[a-zA-Z ,.'-]*$/)

export default {
  name: 'Characters',
  mixins: [DropdownPositionCalculator, TourManager],
  data() {
    return {
      fieldsErrors: {},
      isRandomizeLoading: false,
      newCharacter: {
        firstName: '',
        lastName: '',
        birthDate: '',
        gender: '',
        weight: 0,
        height: 0,
        ethnicity: '',
        eyeColor: '',
        hairColor: ''
      },
      initialNewCharacter: null,
      tourSteps: [
        {
          target: '.v-step-all-characters',
          content: this.$t('tour.characters.all-characters'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-add-character',
          content: this.$t('tour.characters.add-character'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-continue-button',
          content: this.$t('tour.characters.continue-button-1'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-continue-button',
          content: this.$t('tour.characters.continue-button-2'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        }
      ]
    }
  },
  validations: {
    newCharacter: {
      firstName: {
        required,
        alphaDash
      },
      lastName: {
        required,
        alphaDash
      },
      birthDate: {
        required
      },
      height: {
        required,
        decimal
      },
      weight: {
        required,
        decimal
      },
      ethnicity: {
        required
      },
      gender: {
        required
      },
      hairColor: {
        required
      },
      eyeColor: {
        required
      }
    }
  },
  computed: {
    selectedCharacter: {
      set(character) {
        this.$store.commit('selection/character', character)
      },
      get() {
        if (this.$store.state.selection.character === null) {
          return 0
        }

        return this.$store.state.selection.character
      }
    }
  },
  methods: {
    ...mapGetters('auth', ['checkCurrentPermissions']),
    addCharacter: function() {
      return new Promise((resolve, reject) => {
        if (this.$v.$invalid) {
          this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
          this.fieldsErrors = {
            firstName: validationMessage(this.$v.newCharacter.firstName),
            lastName: validationMessage(this.$v.newCharacter.lastName),
            birthDate: validationMessage(this.$v.newCharacter.birthDate),
            height: validationMessage(this.$v.newCharacter.height),
            weight: validationMessage(this.$v.newCharacter.weight),
            ethnicity: validationMessage(this.$v.newCharacter.ethnicity),
            gender: validationMessage(this.$v.newCharacter.gender),
            hairColor: validationMessage(this.$v.newCharacter.hairColor),
            eyeColor: validationMessage(this.$v.newCharacter.eyeColor)
          }

          reject(new Error(this.$t('errors.FIX_ERRORS'))) // We resolve instead of rejecting because we want to handle the error ourself
          return
        }

        const civilian = this.newCharacter
        civilian.height = parseFloat(civilian.height)
        civilian.weight = parseFloat(civilian.weight)

        this.$store
          .dispatch('selection/addCharacter', civilian)
          .then(response => {
            this.$store.dispatch(
              'subscriptionPlanEnforcement/updateCiviliansCount',
              this.$store.state.selection.characters.length
            )

            this.newCharacter = Object.assign({}, this.initialNewCharacter)
            resolve(response)
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
            reject(error)
          })
      })
    },
    randomizeCharacter() {
      this.isRandomizeLoading = true

      // axios.get('https://api.namefake.com').then(character => {
      this.$store.dispatch('civilian/randomize').then(character => {
        this.isRandomizeLoading = false

        if (character.name.split(' ').length === 2) {
          this.newCharacter.firstName = character.name.split(' ')[0]
          this.newCharacter.lastName = character.name.split(' ')[1]
        } else {
          this.newCharacter.firstName = character.name.split(' ')[1]
          this.newCharacter.lastName = character.name.split(' ')[2]
        }
        this.newCharacter.birthDate = character.birth_data
        this.newCharacter.gender = character.pict.includes('female') ? 'FEMALE' : 'MALE'
        this.newCharacter.height = character.height
        this.newCharacter.weight = character.weight
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.newCharacter.ethnicity = Object.keys(this.$t('ethnic-group-attribute'))[
          Math.floor(Math.random() * Math.floor(Object.keys(this.$t('ethnic-group-attribute')).length))
        ]

        if (character.eye === 'Gray') {
          this.newCharacter.eyeColor = 'BLUE'
        } else if (character.eye === 'Hazel') {
          this.newCharacter.eyeColor = 'BROWN'
        } else if (!Object.keys(this.$t('eye-color-attribute')).includes(character.eye.toUpperCase())) {
          this.newCharacter.eyeColor = 'OTHER'
        } else {
          this.newCharacter.eyeColor = character.eye.toUpperCase()
        }

        if (!Object.keys(this.$t('hair-color-attribute')).includes(character.hair.split(', ')[1].toUpperCase())) {
          this.newCharacter.hairColor = 'OTHER'
        } else {
          this.newCharacter.hairColor = character.hair.split(', ')[1].toUpperCase()
        }

        this.$v.$touch()
      })
    },
    editCharacter(character) {
      this.selectedCharacter = character

      this.$router.push({ name: 'civilian', hash: '#edit' })
    },
    unloadHandler: function(event) {
      if (this.$store.state.selection.character.officer && this.$store.state.selection.unit) {
        this.$store.dispatch('police/setOfficerAsLeaving', {
          officerId: this.$store.state.selection.character.officer.id,
          unitId: this.$store.state.selection.unit.id
        })
      }
    }
  },
  created() {
    window.addEventListener('beforeunload', this.unloadHandler)

    if (!this.checkCurrentPermissions()(this.$store.state.selection.role)) {
      this.$router.push({ name: 'roles' })
      return
    }

    this.initialNewCharacter = Object.assign({}, this.newCharacter)

    this.$store
      .dispatch('selection/fetchCharacters')
      .then(response => {
        this.$store.dispatch(
          'subscriptionPlanEnforcement/updateCiviliansCount',
          this.$store.state.selection.characters.length
        )
        if (this.$store.state.selection.character) {
          response.forEach(character => {
            if (character.id === this.$store.state.selection.character.id) {
              this.$store.commit('selection/character', character)
            }
          })
        }
      })
      .catch(error => {
        this.$store.dispatch('errorManager/showError', { error: error })
      })

    if (this.$store.state.selection.unit) {
      this.$store
        .dispatch('selection/exitUnit')
        .then(response => {
          this.$store.commit('selection/unit', null)
        })
        .catch(error => {
          if (error.graphQLErrors) {
            let errors = []

            error.graphQLErrors.map(({ code, extensions }) => {
              if (code && code === 3041) {
                errors.push('INVALID_UNIT_ID')
              }

              if (extensions) {
                errors = Object.values(extensions)
              }
            })

            if (errors.includes('INVALID_UNIT_ID')) {
              this.$store.commit('selection/unit', null)
            } else {
              this.$store.dispatch('errorManager/showError', { error: error })
            }
          }
        })
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unloadHandler)
  }
}
</script>
